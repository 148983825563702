import React, { useMemo, Suspense } from "react"
import { styled } from "../../../stitches.config"

import { withThemeContext } from "../StitchesThemeProvider";

import { body } from "../../commonStyles/Fonts";
import { bodyLarge } from "../../commonStyles/Fonts";
import { dLabel } from "../../commonStyles/Fonts";
import Link from "../../../components/Link";
import Logo from "../../atoms/Logo";
import FooterLogo from "./FooterLogo"

import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-up-icon.svg?react"
import { ReactComponent as ArrowIconBlack } from "../../assets/icons/arrow-up-icon-black.svg?react"

import SocialIcon from "../../atoms/SocialIcon";
import BrandsConfig from "../../../config";

const FooterBackground = React.lazy(() => import(/* webpackChunkName: "redesign__components__FooterBackground" */ './FooterBackground'));

function scrollUp() {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
}

type FooterProps = {
    isDarkMode?: boolean;
    brand?: Config.Brand;
};

export const Footer: React.FC<FooterProps> = ({
    isDarkMode,
    brand = window.BRAND
}) => {

    const mainDomain = window.APP_SETTINGS.main_domain

    const karieraMainDomainLink = mainDomain ? `https://${mainDomain}/kariera` : `/kariera`;
    const redakcjaMainDomainLink = mainDomain ? `https://${mainDomain}/redakcja` : `/redakcja`;
    const contactMainDomainLink = mainDomain ? `https://${mainDomain}/contact` : `/contact`;
    const blogsMainDomainLink = mainDomain ? `https://${mainDomain}/blogs` : `/blogs`;
    const postsMapMainDomainLink = mainDomain ? `https://${mainDomain}/posts-map` : `/posts-map`;
    const regulaminMainDomainLink = mainDomain ? `https://${mainDomain}/info/regulamin` : `/info/regulamin`;
    const politykaPrywatnosciMainDomainLink = mainDomain ? `https://${mainDomain}/info/polityka-prywatnosci` : `/info/polityka-prywatnosci`;

    const TOP_BAR_ATOMS = useMemo(() => {
        const type = isDarkMode ? "dark" : "white"
        return Object.entries(BrandsConfig).filter(([_, brandConfig]) => !brandConfig.inActive).reduce((atoms, [brandName, brandConfig]) => {
            atoms[brandName] = (
                <Link to={`https://${brandConfig.brandUrlName ? brandConfig.brandUrlName : brandName}.pl`}>
                    <Logo type={type} brand={brandName as Config.Brand} />
                </Link>
            );

            return atoms;
        }, {} as any);
    }, [isDarkMode])

    const { [window.BRAND]: mainService, ...otherServices } = TOP_BAR_ATOMS;
    return <StyledFooter>
        <FooterTop>
            <Services>
                <MainService>
                    <FooterLogo
                        className="footer-top--logo"
                        brand={window.BRAND as any}
                        type={isDarkMode ? "dark" : "white"}
                    />
                    <p className={body()}>
                        {BrandsConfig[brand]?.footerDescription}
                    </p>
                </MainService>
                <OtherServices>
                    <p className={dLabel()}>GRUPA NATEMAT</p>
                    {Object.entries(otherServices).map(([key, value]) => (
                        //@ts-ignore
                        <Badge key={key}>{value}</Badge>
                    ))}
                </OtherServices>
            </Services>
            <Links className={bodyLarge()}>
                <div>
                    <Link to={contactMainDomainLink}>Biuro reklamy</Link>
                    <Link to={karieraMainDomainLink}>Kariera</Link>
                    <Link to={redakcjaMainDomainLink}>Skład redakcji</Link>
                    <Link to={contactMainDomainLink}>Kontakt</Link>
                </div>
                <div>
                    <Link to={blogsMainDomainLink}>Blogi</Link>
                    {/* komponent Link nie przesyła poprawnie mapy artykułów */}
                    <a href={postsMapMainDomainLink}>Mapa artykułów</a>
                    <Link to={regulaminMainDomainLink}>Regulamin</Link>
                    <Link to={politykaPrywatnosciMainDomainLink}>Prywatność</Link>
                </div>
            </Links>
            <SocialMobile>
                <SocialIcon type="facebook" />
                <SocialIcon type="instagram" />
                <SocialIcon type="tiktok" />
                <SocialIcon type="youtube" />
            </SocialMobile>
            <OtherServicesMobile>
                <p className={dLabel()}>GRUPA NATEMAT</p>
                {Object.entries(otherServices).map(([key, value]) => (
                    //@ts-ignore
                    <Badge key={key}>{value}</Badge>
                ))}
            </OtherServicesMobile>

            <ButtonUp onClick={scrollUp}>DO GÓRY<span>{isDarkMode ? <ArrowIconBlack /> : <ArrowIcon />}</span></ButtonUp>

        </FooterTop>
        <FooterBottom className={body()}>
            <p>© 2022 Wszystkie prawa zastrzeżone Grupa naTemat, ul. Burakowska 5/7, 01-066 Warszawa</p>
            <div>
                <SocialIcon type="facebook" />
                <SocialIcon type="instagram" />
                <SocialIcon type="tiktok" />
                <SocialIcon type="youtube" />
            </div>
        </FooterBottom>
        {/* background images */}
        <Suspense fallback={null}>
            <FooterBackground isDarkMode={isDarkMode} />
        </Suspense>
    </StyledFooter>
}

const StyledFooter = styled('footer', {
    width: "100%",
    background: "$grayscale0",
    position: "relative",
    padding: "0 16px 72px 16px",
    "@bp4": {
        padding: 0,
    },
    "& p": {
        margin: 0,
    }
})

const MainService = styled('div', {
    position: "relative",
    zIndex: 2,
    "& p": {
        margin: "16px 0 32px 0",
        "@bp4": {
            margin: "12px 0 32px 0 !important",
        },
    }
})


const ButtonUp = styled('button', {
    fontFamily: "$grotesk",
    fontWeight: "800",
    color: "$grayscale0",
    width: "max-content",
    height: "max-content",
    background: "$grayscale100",
    borderRadius: "24px",
    borderWidth: "0px",
    padding: "4px 12px",
    display: "flex",
    cursor: "pointer",
    margin: "32px auto 0 auto",
    position: "relative",
    zIndex: 2,
    fontSize: "12px",
    lineHeight: "16px",
    "& svg": {
        marginLeft: "6px",
        marginBottom: "1px",
    },
    "@bp4": {
        margin: "0",
    },
})

const OtherServices = styled('div', {
    display: "none",
    marginTop: "32px",
    "& p": {
        fontFamily: "$grotesk",
        fontWeight: "800",
        marginBottom: "12px !important",
    },
    "@bp4": {
        display: "block",
    },
})


const OtherServicesMobile = styled('div', {
    marginTop: "32px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& p": {
        fontFamily: "$grotesk",
        fontWeight: "800",
        marginBottom: "12px  !important",
        width: "100%",
    },
    "@bp4": {
        display: "none",
    },
})


const SocialMobile = styled('div', {
    display: "flex",
    justifyContent: "space-between",
    "@bp4": {
        display: "none"
    },
    "& a": {
        display: "flex",
        justifyContent: "center",
        padding: "10px 0",
        border: "1px solid $grayscale25",
        width: "24%",
    }
})

const FooterTop = styled('div', {
    padding: "34px 16px 36px 16px",
    display: "flex",
    flexDirection: "column",
    "@bp4": {
        flexDirection: "row",
        padding: "62px 84px 78px 84px",
        justifyContent: "space-between",
    },
})

const Services = styled('div', {
    borderBottom: "1px solid $grayscale25",
    color: "$grayscale100",
    "@bp4": {
        borderWidth: "0px",
    },
})

const Links = styled('div', {
    margin: "16px 0 32px 0",
    "@bp4": {
        display: "flex",
        margin: "-8px 0 0 0",
    },
    "& a": {
        color: "$grayscale100",
        textDecoration: "none",
        dispaly: "block",
        marginTop: "16px",
        fontSize: "14px",
        lineHeight: "16px",
        transition: "0.3s",
        "@bp4": {
            fontSize: "16px",
            lineHeight: "24px",
        },
        "&:hover": {
            textDecoration: "none",
            color: "$grayscale75",
        }
    },
    "& div": {
        width: "192px",
        display: "flex",
        flexDirection: "column"
    }
})

const Badge = styled("span", {
    width: "calc(50% - 4px)",
    margin: "0 0 8px 0",
    "@bp4": {
        width: "auto",
        margin: "0 6px 0 0",
    },
    "& a": {
        color: "$grayscale100",
        textDecoration: "none",
        display: "flex",
        justifyContent: "center",
        width: "100%",
        padding: "19px 0px",
        background: "$grayscale10",
        "@bp4": {
            display: "inline-block",
            border: "1px solid $grayscale25",
            background: "none",
            padding: "10px 12px",
            width: "max-content",
        },

        "& svg": {
            height: "10px",
            "@bp4": {
                height: "8px",
            },
        },

    },
})

const FooterBottom = styled('div', {
    padding: "16px 0px 24px 0px",
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid $grayscale10",
    color: "$grayscale100",
    "@bp4": {
        padding: "15px 84px 12px 84px",
    },
    "& div": {
        position: "relative",
        zIndex: 2,
        display: "none",
        "@bp4": {
            display: "block",
        }
    },
    "& a": {
        marginLeft: "20px",
    }
})

export default withThemeContext(Footer);