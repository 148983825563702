import React, { ComponentProps, PropsWithChildren } from "react";
import { styled } from "../../../stitches.config";

import Logo from "../../atoms/Logo";


const FooterLogo: React.FC<PropsWithChildren<ComponentProps<typeof LogoComponent>>> = ({ ...props }) => {

    return (
        <LogoComponent
            {...props}
        />
    );

};

const LogoComponent = styled(Logo, {
    width: 148,
    height: 24,
    '@bp4': {
        width: 196,
        height: 32,
    },
    variants: {
        brand: {
            'innpoland': {
                color: "red",
                "& svg": {
                    ".st0innlogo": {
                        fill: "$primary",
                    }
                }
            }
        },
    },
});

export default FooterLogo;